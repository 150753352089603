import styles from "./ProjectCard.module.scss";
import { motion } from "framer-motion";

const { project, projectTitle } = styles;

function ProjectCard({ title, description, link, type, style }) {
  const projectHoverAnimations = {
    scale: 1.01,
    backdropFilter: "blur(4px)",
    boxShadow: "0px 3px 15px rgba(0,0,0,0.1)",
    transition: { type: "spring", mass: 0.01, damping: 10 },
  };

  return (
    <motion.a
      className={project}
      whileHover={
        link ? projectHoverAnimations : { cursor: "default", opacity: 0.5 }
      }
      target="_blank"
      href={link}
      style={style}
    >
      <div className={projectTitle}>
        <h3>{title}</h3>
        <span>{type}</span>
      </div>
      <p>{description}</p>
    </motion.a>
  );
}

export default ProjectCard;
