import styles from "./technical-projects.module.scss";
import LogoPaths from "../../utils/logo-directories";

export const techList = [
  {
    url: LogoPaths.typescript,
    techName: "TypeScript",
    styling: styles.TypeScript,
  },
  {
    url: LogoPaths.react,
    techName: "React",
    styling: styles.React,
  },
  {
    url: LogoPaths.nodejs,
    techName: "Node.js",
    styling: styles.Nodejs,
  },
  {
    url: LogoPaths.express,
    techName: "Express",
    styling: styles.Express,
  },
  {
    url: LogoPaths.docker,
    techName: "Docker",
    styling: styles.Docker,
  },
  {
    url: LogoPaths.react,
    techName: "React Native",
    styling: styles.ReactNative,
  },
  {
    url: LogoPaths.reactQuery,
    techName: "React Query",
    styling: styles.ReactQuery,
  },
  {
    url: LogoPaths.tailwind,
    techName: "Tailwind",
    styling: styles.tailwind,
  },
  {
    url: LogoPaths.mongodb,
    techName: "MongoDB",
    styling: styles.MongoDB,
  },
  {
    url: LogoPaths.angular,
    techName: "Angular 10",
    styling: styles.Angular,
  },
  {
    url: LogoPaths.scss,
    techName: "SCSS",
    styling: styles.SCSS,
  },
  {
    url: LogoPaths.d3,
    techName: "D3.js",
    styling: styles.D3,
  },
  {
    url: LogoPaths.firebase,
    techName: "Firebase",
    styling: styles.Firebase,
  },
  {
    url: LogoPaths.expo,
    techName: "Expo",
    styling: styles.Expo,
  },
  {
    url: LogoPaths.sql,
    techName: "SQL",
    styling: styles.SQL,
  },
  {
    url: LogoPaths.figma,
    techName: "Figma",
    styling: styles.Figma,
  },
];
