import angular from "../images/tech-logos/angular.png";
import tailwind from "../images/tech-logos/tailwind.png";
import js from "../images/tech-logos/js.png";
import react from "../images/tech-logos/react.svg";
import framer from "../images/tech-logos/framer.svg";
import scss from "../images/tech-logos/scss.png";
import d3 from "../images/tech-logos/d3.png";
import firebase from "../images/tech-logos/firebase.svg";
import sql from "../images/tech-logos/sql.png";
import nodejs from "../images/tech-logos/nodejs.svg";
import express from "../images/tech-logos/express.png";
import mongodb from "../images/tech-logos/mongodb.png";
import typescript from "../images/tech-logos/typescript.svg";
import figma from "../images/tech-logos/figma.png";
import docker from "../images/tech-logos/docker.png";
import reactQuery from "../images/tech-logos/reactQuery.png";
import expo from "../images/tech-logos/expo.png";

const LogoPaths = {
  angular,
  tailwind,
  react,
  framer,
  scss,
  firebase,
  sql,
  d3,
  nodejs,
  express,
  mongodb,
  typescript,
  figma,
  docker,
  js,
  reactQuery,
  expo,
};

export default LogoPaths;
