import { useState, useEffect } from "react";
import ProgressLoader from "../components/progress-loader/progress-loader";
import Hero from "./hero/Hero";
import TechnicalProjects from "./technical-projects/technical-projects";
import AboutMe from "./about-me/about-me";
import styles from "./Layout.module.scss";
const { layout, page } = styles;

function Layout() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      window.scrollTo({ top: 0 });
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && <ProgressLoader />}
      <div className={layout}>
        <Hero isLoading={isLoading} className={page}></Hero>
        <TechnicalProjects className={page}></TechnicalProjects>
        <AboutMe></AboutMe>
      </div>
    </>
  );
}

export default Layout;
