import styles from "./technical-projects.module.scss";
import ProjectCard from "../../components/ProjectCard";
import { techList } from "./utils";

const {
  technicalProjects,
  upperLeft,
  upperRight,
  downLeft,
  downRight,
  divider,
  subDivider,
  techItem,
  imageContainer,
  techImage,
  techName,
  projectHolder,
  projectsContainer,
  circleBottom,
} = styles;

function TechnicalProjects() {
  const technologies = techList.map((tech) => {
    const className = `${techImage} ${tech.styling}`;

    return (
      <div key={tech.techName} className={techItem}>
        <div className={imageContainer}>
          <img
            alt="Christopher Flodin"
            className={className}
            src={tech.url}
          ></img>
        </div>
        <div className={techName}>{tech.techName}</div>
      </div>
    );
  });

  return (
    <section id="tech-projects" className={technicalProjects}>
      <div className={circleBottom}></div>
      <div className={projectsContainer}>
        <div className={upperLeft}>
          <h2>Technologies</h2>
          <p>
            These are the tools, frameworks, and programming languages I've been
            using in past projects.
          </p>
        </div>
        <div className={upperRight}>{technologies}</div>
        <div className={divider}></div>

        <div className={downLeft}>
          <h2>Projects</h2>
          <p>
            Projects I have worked on as a software developer, generally in
            smaller tight-knight teams with agile methodologies.
          </p>
        </div>

        <div className={downRight}>
          <div className={projectHolder}>
            <ProjectCard
              title="albacross.com"
              type="Marketing Tech"
              description="A lead generation product that streamlines prospecting and sales
                with proprietary data."
              link="https://albacross.com/"
            />
            <ProjectCard
              title="foreningslivet.se"
              type="Startup"
              description="An app and a startup whose purpose is to increase social
                inclusion for the general population in Sweden."
              link="https://foreningslivet.se/"
              style={{ marginTop: "10px" }}
            />
          </div>

          <div className={projectHolder}>
            <ProjectCard
              title="Paladium"
              type="Analytics"
              description="An analytics platform with all the graphs and charts
                you would expect. Think mix panel for video games."
            />
            <ProjectCard
              title="Smileys by futurice"
              type="Internal"
              description="A Futurice product allowing remote teams to more efficiently run check-ins during retrospectives and similar meetings"
              link="https://smileysbeta.vercel.app/"
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div className={subDivider}></div>
      </div>
    </section>
  );
}

export default TechnicalProjects;
