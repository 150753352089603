import "./progress-loader.scss";

function ProgressLoader() {
  return (
    <div className="loading-container">
      <div className="center-circle"></div>
    </div>
  );
}

export default ProgressLoader;
