import classNames from "classnames";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import styles from "./Hero.module.scss";
import HeroCanvas from "./hero-canvas/HeroCanvas";

const cn = classNames;

const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

const {
  heroPageContainer,
  greetingContainer,
  greeting,
  greetingName,
  greetingParagraph,
  secondParagraph,
  btnContainer,
  imgWrapper,
  img,
  boxShadow,
} = styles;

const animationTime = 0.4;
const loadingTime = 2;
const animationStagger = 0.25;
const animationYLength = -15;

function Hero(props) {
  const greetingRef = useRef();
  const greetingNameRef = useRef();
  const greetingParagraphRef = useRef();
  const imageRef = useRef();
  const [paintClicked, setPaintClicked] = useState(false);
  const [cleanClicked, setCleanClicked] = useState(false);

  const onPaint = () => {
    setPaintClicked((prev) => !prev);
  };

  const onClean = () => {
    setCleanClicked((prev) => !prev);
  };

  const baseVariant = {
    initial: { opacity: 0, y: animationYLength },
    animate: {
      y: 0,
      opacity: 1,
      textShadow: "2px 3px 5px rgba(0, 0, 0, 0.3)",
    },
  };

  const hiVariants = deepCopy(baseVariant);
  hiVariants.animate.transition = {
    duration: animationTime,
    delay: loadingTime,
  };

  const nameVariants = deepCopy(baseVariant);
  nameVariants.animate.transition = {
    duration: animationTime,
    delay: loadingTime + animationStagger,
  };

  const descriptionVariants = deepCopy(baseVariant);
  descriptionVariants.animate.transition = {
    duration: animationTime,
    delay: loadingTime + animationStagger * 2,
  };

  const buttonVariants = deepCopy(baseVariant);
  buttonVariants.animate.transition = {
    duration: animationTime,
    delay: loadingTime + animationStagger * 3,
  };

  return (
    <div className={heroPageContainer}>
      <HeroCanvas
        cleanClicked={cleanClicked}
        paintClicked={paintClicked}
        greetingNameRef={greetingNameRef}
      ></HeroCanvas>
      <div className={greetingContainer}>
        <motion.h2
          ref={greetingRef}
          className={greeting}
          variants={hiVariants}
          initial="initial"
          animate="animate"
        >
          Hi, I'm
        </motion.h2>
        <motion.h1
          ref={greetingNameRef}
          className={greetingName}
          variants={nameVariants}
          initial="initial"
          animate="animate"
        >
          <div className={imgWrapper}>
            <img
              className={cn([img, boxShadow])}
              ref={imageRef}
              src={require("../../images/chris_profile.jpg")}
              alt=""
            />
          </div>
          Christopher Flodin
        </motion.h1>
        <motion.p
          ref={greetingParagraphRef}
          className={greetingParagraph}
          variants={descriptionVariants}
          initial="initial"
          animate="animate"
        >
          I build software for big and small companies{" "}
          <span className={secondParagraph}>
            Currently building <a href="https://www.mevisio.com/">@Mevisio</a>
          </span>
        </motion.p>
        <motion.div
          className={btnContainer}
          variants={buttonVariants}
          initial="initial"
          animate="animate"
        >
          <motion.button
            whileTap={{
              scale: 0.85,
              transition: { type: "spring", mass: 0.1 },
            }}
            className="pink"
            onClick={onPaint}
          >
            Paint
          </motion.button>
          <motion.button
            whileTap={{
              scale: 0.85,
              transition: { type: "spring", mass: 0.1 },
            }}
            className="blue"
            onClick={onClean}
          >
            Clean
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
}

export default Hero;
